
.App {
  display: flex;
  flex-direction: column;
}

.Header {
  display: flex;
  background-color: #24398A;
  align-items: center;
  justify-content: space-between;
  padding: 10px 40px;
  min-height: 60px;
}

.tabs {
  display: flex;
  gap: 20px;
}

.tabIcon {
  border: 1px solid white;
  padding: 8px;
  border-radius: 5px;
  width: 40px;
  height: 40px;
  fill: white;
}

.profil {
  display: flex;
  align-items: center;
  height: auto;
  gap: 10px;
  position: relative;
  padding: 0 10px 0 0;
}

.username {
  color: white;
  font-size: 20px;
  font-weight: 600;
}

.body {
  display: flex;
  flex-direction: column;
  /* border: 1px solid pink; */
  padding: 20px 20%;
  gap: 20px;
}

.stat-title {
  display: flex;
  gap: 10px;
  justify-content: center;
  font-size: 20px;
}

.logo {
  width: auto;
}

.filter {
  display: flex;
  justify-content: space-between;
}

.filter-names {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.floating-menu {
  position: absolute;
  border: 1px solid #24398A;
  border-radius: 10px;
  top: 80px;
  right: 0;
  width: 150px;
  padding: 10px 10px;
}

.floating-label {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
}

.floating-label:hover {
  font-weight: 700;
}

#filter-statut {
  background-color: #24398A;
  color: white;
  font-size: 15px;
  font-weight: 600;
  height: 38px;
  padding: 0 10px;
  border-radius: 10px;
  border: none;
}

#filter-statut:focus-visible {
  outline: none;
}

#search {
  width: auto;
}

.filter-card {
  display: flex;
  align-items: center;
  gap: 10px;
}

.label-check {
  font-size: 16px;
  font-weight: 500;
}

.controls {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
}

.ctrl-icon {
  width: 60px;
  height: 60px;
}


/* TOGGLE */

.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #4D4D4D;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
  background-color: #24398A;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #24398A;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #4D4D4D;
  border-radius: 50%;
  background-color: #FAFAFA;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #24398A;
}

@media only screen and (max-width: 600px) {

    .filter {
      flex-direction: column;
      gap: 20px;
    }
    
    .tabIcon {
      width: 20px;
      height: 20px;
    }

    .body {
      padding: 20px 20px;
    }

    .floating-menu {
      top: 50px;
      right: -20px;
      width: fit-content;
      padding: 10px 10px;
    }
    
    .floating-label {
      font-size: 12px;
      font-weight: 500;
      text-align: center;
      cursor: pointer;
    }
        
}