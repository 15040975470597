* {
    padding: 0;
    margin: 0;
}

.lead {
    display: flex;
    flex-direction: column;
    border: 1px solid #6B6B6B;
    border-radius: 20px;
    padding: 20px;
    gap: 25px;
    margin-bottom: 30px;
}

.names {
    font-size: 34px;
    color: #24398A;
}

.input-names {
    width: fit-content;
    gap: 10px;
    display: flex;
}

.input-name {
    font-size: 34px;
    font-weight: 700;
    color: #24398A;
    border: unset;
    background-color: unset;
    padding: 0;
    height: fit-content;
    width: 45%;   
    border: 1px solid #24388ac0;
    padding: 0 10px;
}

#input-name-save {
    width: 40px;
    height: 40px;
}

.row-1 {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    align-items: flex-end;
}

.row-1>.content-copy-icon {
    vertical-align: bottom;
}

.infoCol {
    display: flex;
    flex-direction: column;
    align-content: stretch;
    flex: 1 1 0;
    gap: 5px;
}

.info-label {
    font-weight: 500;
    font-size: 18px;
    color: #252525;
    opacity: 75%;
}

.info-value {
    font-weight: 700;
    font-size: 18px;
    color: #252525;
}

.update-col {
    padding: 0;
    display: flex;
    align-items: center;
}

#update-value {
    border: none;
    width: 100%;
    height: unset;
    border-radius: unset;
    padding: unset;
    background-color: unset;
    font-weight: 700;
    font-size: 18px;
    color: #252525;
}

#update-value::placeholder {
    opacity: 0.5;
    font-weight: 400;
    font-size: medium;
}


#saveIcon {
    width: 20px;
    height: 20px;
}

.commentaire {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.com-col {
    display: flex;
    border: 1px solid #6B6B6B;
    border-radius: 20px;
    padding: 10px;
    min-height: 50px;
    align-items: center;
    justify-content: space-between;
}

.info-commentaire {
    align-self: flex-start;
    font-weight: 600;
}

#com-value {
    border: none;
    width: unset;
    height: unset;
    border-radius: unset;
    padding: unset;
    background-color: unset;
    font-weight: 700;
    font-size: 18px;
    color: #24398A;
    flex-grow: 1;
    min-height: 50px;
}

#saveIconCom {
    width: 40px;
    height: 40px;
}

.footer {
    background-color: #24398A;
    height: 50px;
    margin: 0 -21px -21px -21px;
    border-radius: 0 0 20px 20px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    justify-content: space-between;
}

.footer-agent {
    display: flex;
    gap: 10px;
}

.footer-label {
    font-weight: 500;
    font-size: 18px;
    color: #FFF;
    opacity: 75%;
}

.footer-value {
    font-weight: 700;
    font-size: 18px;
    color: #FFF;
}

.footer-status {
    display: flex;
    gap: 10px;
}

.dropdown-statut {
    background-color: unset;
    color: #FFF;
    border: unset;
    font-family: inherit;
    font-size: 18px;
    font-weight: 600;
}

.dropdown-statut:focus-visible {
    outline: unset;
}

.statut-list {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.statut-item {
    display: flex;
    border: 1px solid #24388a43;
    flex-direction: column;
    flex: 1 0 calc(15%); /* Répartir les éléments en 4 colonnes avec 10px de marge */
    gap: 8px;
    padding: 4px;
    border-radius: 8px;
    justify-content: space-between;
}

.statut-label {
    color: #6B6B6B;
    font-weight: bold;
}

.statut-value {
    color: #24398A;
    font-weight: bold;
    font-size: 20px;
}

@media only screen and (max-width: 600px) {

    .infoCol {
        flex-grow: 2;
    }

    .info-label {
        font-size: 12px;
    }

    #update-value {
        font-size: 12px;
    }

    .footer-label {
        font-size: 12px;
    }

    .footer-value {
        font-size: 12px;
    }

    .dropdown-statut {
        font-size: 12px;
    }
}